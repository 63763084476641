import React from 'react'
import { useRecoilState } from 'recoil'
// custom imports
import GameState from '../atoms/GameState'

const Answers = ({answers, answersImages, correctAnswer, colors}) => {
  const [gameState] = useRecoilState(GameState)

  const answerClass = (index) => {
    if(gameState.showAnswers === true) {
      if(correctAnswer === index) return 'correct'
      return 'wrong'
    }
    return ''
  }

  return (
    <div className="answers">
      {answers.map((answer, index) => (
        <div  className={'answer ' + answerClass(index + 1)} 
              style={{backgroundColor: colors[index]}}
              key={'answer-' + index}
        >
          {/* Image only */}
          {answersImages && answersImages[index] && 
            <img src={URL.createObjectURL(answersImages[index])} alt="Game Thumbnail" style={{height: '100%'}} />
          }
          {/* Text only  */}
          {answersImages === undefined && `${answer}`}
        </div>
      ))}
    </div>
  )
}

export default Answers
