import React, { useEffect, useState } from 'react'
import rndGradient from '../utils/RndGradient'

const Question = ({ question }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const hasImage = question.image ? true : false

  useEffect(() => {
    if (question.image) {
      setImageUrl(URL.createObjectURL(question.image))
    }else{
      setImageUrl(null)
    }
  }, [question])

  return (
    <div className={"question " + rndGradient()}>
      {(hasImage) ? '' : <h1>{question.question}</h1>}
      {(hasImage) && <img src={imageUrl} alt="Question Thumbnail" style={{height: '95%', boxShadow: '0px 0px 30px rgba(0,0,0,0.6)'}} />}
    </div>
  )
}

export default Question