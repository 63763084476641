// 3rd party imports
import { useEffect, useState } from "react"
import { db } from "../utils/db"

const CategoriesFilter = ({ selectedCategories, setSelectedCategories }) => {
  const [availableCategories, setAvailableCategories] = useState([])
  const backendUrl = 'https://strapi.sherlock.gmbh'

  const getLocalCategories = async () => {
    const categories = await db.categories.toArray()
    return categories.map(cat => ({
      id: cat.id,
      attributes: { Name: cat.name }
    }))
  }

  const saveCategoriesToLocal = async (categories) => {
    // Clear existing categories
    await db.categories.clear()
    // Add new categories
    const categoriesToAdd = categories.map(cat => ({
      name: cat.attributes.Name
    }))
    await db.categories.bulkAdd(categoriesToAdd)
  }

  const getAvailableCategories = async () => {
    try {
      const populate = ['Name']
      let populateQuery = populate.map((item, index) => `populate[${index}]=${item}`).join('&')
      const response = await fetch(`${backendUrl}/api/categories?${populateQuery}&pagination[pageSize]=500`, {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      })
      const result = await response.json()
      
      if (Array.isArray(result.data) && result.data.length > 0) {
        setAvailableCategories(result.data)
        // Store categories locally for offline use
        await saveCategoriesToLocal(result.data)
      }
    } catch (error) {
      console.log('Failed to fetch categories from backend, using local data')
      const localCategories = await getLocalCategories()
      if (localCategories.length > 0) {
        setAvailableCategories(localCategories)
      }
    }
  }

  useEffect(() => {
    getAvailableCategories()
  }, [])

  return (
    <>
      {availableCategories.length > 0 && <div className="categoryList">
        {selectedCategories.length > 0 && <div className="categories-selected">
          {selectedCategories.map((cat) => {
            return <button key={cat} className="categories-remove" onClick={() => {
              setSelectedCategories(selectedCategories.filter(catName => catName !== cat))
            }}>{cat}</button>
          })}
        </div>}
        <div className="categories-available">
          {availableCategories.map((category) => {
            // check if category is already selected
            if(!selectedCategories.includes(category.attributes.Name)) {
              return <button key={category.id} className="category" onClick={() => {
                setSelectedCategories([...selectedCategories, category.attributes.Name])
              }}>{category.attributes.Name}</button>
            }
          })}
        </div>
      </div>}
    </>
  )
}

export default CategoriesFilter
