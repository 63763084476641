// 3rd Party Imports
import { useEffect, useState, useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
// Components
import GameControl from '../components/GameControl'
import Question from '../components/Question'
import Answers from '../components/Answers'
import Hud from '../components/Hud'
// Atom States
import GameState from '../atoms/GameState'
import GamesState from '../atoms/GamesState'
// Games
import { db } from '../utils/db'
// Sounds
import RndPlay from '../sfx'

const QuizScreen = () => {
  const msToShowAnswers = 3000 // 3 seconds
  const navigate = useNavigate()  
  const [gameState, setGameState] = useRecoilState(GameState)  
  const [showAnswersTimeoutActive, setShowAnswersTimeoutActive] = useState(false)
  const [games, setGames] = useRecoilState(GamesState)
  const [questions, setQuestions] = useState([])

  // which question is currently active
  const [current, setCurrent] = useState(0)

  const loadInstalledGames = async () => {
    const g = await db.games.toArray()
    setGames(g)
    // find game by id in games array using gameState.selectedGame
    const selectedGame = g.find(game => game.id === gameState.selectedGame);
    if (!selectedGame) {
      console.log('Selected game not found');
      return;
    }
    // set questions
    setQuestions(selectedGame.questions)
    console.log('loadInstalledGames', games)
    console.log('selectedGame', selectedGame)
  }

  const nextQuestionOrResult = () => {    
    setShowAnswers(false)
    if (nextQuestion()) {
      console.log('switch question')
      // alternate teams          
      if(['alternating-next-question'].includes(gameState.gameMode)) {
        console.log('change active team')
        setActiveTeam((gameState.activeTeam.includes(0)) ? 1 : 0)
      }
    }else{
      navigate('/result')
    }
  }

  useEffect(() => {
    if (showAnswersTimeoutActive) {
      const timer = setTimeout(() => {
        console.log('Show answers timeout done after: ', msToShowAnswers, 'ms')
        setShowAnswersTimeoutActive(false)        
        // setShowAnswers(false)
        // // do we have more questions?
        // if (current < questions.length - 1) {
        //   setCurrent((current) => (current + 1))
        //   // alternate teams          
        //   if(['alternating-next-question'].includes(gameState.gameMode)) {
        //     console.log('change active team')
        //     setActiveTeam((gameState.activeTeam.includes(0)) ? 1 : 0)
        //   }
        // } else {
        //   navigate('/result')
        // }
        nextQuestionOrResult()
      }, msToShowAnswers)

      return () => {
        console.log('clear timeout', msToShowAnswers, 'ms')
        clearTimeout(timer)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnswersTimeoutActive])

  const nextQuestion = useCallback(() => {    
    if (current < questions.length - 1) {
      setCurrent((current) => (current + 1))
      return true
    }
    return false
  }, [current, questions, setCurrent])

  const setShowAnswers = (showAnswers) => {
    setGameState((gameState) => {
      return {
        ...gameState,
        showAnswers,
      }
    })
  }

  const previousQuestion = useCallback(() => {   
    setShowAnswers(false) 
    if (current > 0) {
      setCurrent((current) => (current - 1))
      return true
    }
    return false
  }, [current, setCurrent, setShowAnswers])

  const stopQuiz = () => {
    resetAnwers() // we reset the answers to empty arrays
    resetScores() // we reset the scores to 0
    setCurrent(0) // we start the quiz at first question again
    setActiveTeam(0) // set active team to 0    
  }

  const setActiveTeam = (teamId) => {
    setGameState((gameState) => {
      return {
        ...gameState,
        activeTeam: [teamId],
      }
    })
  }

  const resetAnwers = () => {
    // set answers to empty array
    setGameState((gameState) => {
      return {
        ...gameState,
        answers: [[],[]],
      }
    })
  }

  const resetScores = () => {
    // set scores to 0
    setGameState((gameState) => {
      return {
        ...gameState,
        scores: [0,0],
      }
    })
  }

  const checkAnswer = useCallback((answer) => {
    console.log('check answer', answer, questions)
    if (questions && questions.length > 0 && answer === questions[current].correctAnswer) {
      console.log('correct answer')
      RndPlay('correct')
      return true
    }
    console.log('wrong answer')
    RndPlay('error')
    return false
  }, [current, questions])

  const answeredByTeam = useCallback((key) => {
    const teamId = Math.floor(gameState.possibleKeys.indexOf(key) / 2)
    console.log('answered by team', teamId)
    return teamId
  }, [gameState.possibleKeys])

  const addAnswerToTeam = useCallback((teamId, isCorrect) => {
    setGameState((gameState) => {
      const newAnswers = [...gameState.answers]
      newAnswers[teamId] = [...newAnswers[teamId], isCorrect]
      console.log('newAnswers', newAnswers)
      return {
        ...gameState,
        answers: newAnswers,
      }
    })
  }, [setGameState])

  const addScoreToTeam = useCallback((teamId) => {
    setGameState((gameState) => {
      const newScores = [...gameState.scores]
      newScores[teamId] = newScores[teamId] + gameState.pointsPerCorrectAnswer
      return {
        ...gameState,
        scores: newScores,
      }
    })
  }, [setGameState])  

  const showAnswers = () => {
    setShowAnswers(true)
    if(gameState.switchQuestions === 'auto') {
      setShowAnswersTimeoutActive(true)
    }else{
      // if we are in game mode alternating-till-true or alternating-next-question we override switchQuestions setting to auto
      if(['alternating-till-true', 'alternating-next-question'].includes(gameState.gameMode)) {
        setShowAnswersTimeoutActive(true)
      }
    }
  }

  // based on the state of answers, we can determine what to do next
  useEffect(() => {
    // if we have no answers, we do nothing
    if (gameState.answers.every((answer) => answer.length === 0)) {
      console.log('no answers yet')
    }else{
      console.log('answers changed', gameState.answers)
      // use the show answers timeout to go to next question
      if(['alternating-next-question'].includes(gameState.gameMode)) {
        showAnswers()
      }else if(['alternating-till-true'].includes(gameState.gameMode)){
        // for mode alternating-till-true we switch to the other team
        setActiveTeam((gameState.activeTeam.includes(0)) ? 1 : 0)
      }else if(['all-at-once-competitive', 'all-at-once-non-competitive'].includes(gameState.gameMode)) {          
        // did all teams answer?
        const allTeamsAnswered = gameState.answers.every((answer) => answer.length > current)
        // console.log('all-at-once-competitive check if all teams answered: ', allTeamsAnswered, ' answers: ', gameState.answers)
        if (allTeamsAnswered) {
          showAnswers()
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.answers])


  useEffect(() => {
    const keydownListener = (event) => {      
      if (gameState.possibleKeys.includes(event.key)) {
        const index = gameState.possibleKeys.indexOf(event.key)
        const isCorrect = checkAnswer(index % 2 === 0 ? 1 : 2)

        // based on the key pressed, we know which team answered
        const teamId = answeredByTeam(event.key)

        // check if this team is allowed to answer
        if (!gameState.activeTeam.includes(teamId)) return        

        // check if answer is correct
        if (isCorrect) {

          // if game mode is one of all-at-once
          if(['all-at-once-competitive', 'all-at-once-non-competitive'].includes(gameState.gameMode)) {
            // check if this team already answered
            if (gameState.answers[teamId].length > current) return        

            // check if this team is the first to answer this question
            if (gameState.firstAnsweredByTeam[current] === undefined) {
              setGameState((gameState) => {
                const newFirstAnsweredByTeam = [...gameState.firstAnsweredByTeam]
                newFirstAnsweredByTeam[current] = teamId
                return {
                  ...gameState,
                  firstAnsweredByTeam: newFirstAnsweredByTeam,
                }
              })
              // add score to team if all-at-once-competitive
              if(['all-at-once-competitive'].includes(gameState.gameMode)) {       
                // this team gets the points for being the first to answer     
                addScoreToTeam(teamId)
              }
            }
          }

          // add score to team for correct answer
          addScoreToTeam(teamId)

          // add correct answer to team
          addAnswerToTeam(teamId, true)
          
          // if there is a next question, go to next question or results
          // if( ['alternating-till-true'].includes(gameState.gameMode) &&
          //     gameState.switchQuestions === 'auto') {
          //   (nextQuestion()) ? console.log('next question available') : navigate('/result')
          // }
        }else{
          // add wrong answer to team
          addAnswerToTeam(teamId, false)
        }
      }
    }
    document.addEventListener('keydown', keydownListener)
    // remove event listener for keydown
    return () => {
      document.removeEventListener('keydown', keydownListener)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    current, gameState, setGameState,
    checkAnswer, answeredByTeam, nextQuestion, addAnswerToTeam, addScoreToTeam,
    navigate
  ])

  useEffect(() => {
    const init = async () => {
      // get questions from local storage
      await loadInstalledGames()
    } 
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    {questions.length === 0 && <div>loading...</div>}
    {questions.length > 0 && (<>
      <Hud
        current={current}
        questions={questions}
      />

      <Question
        question={questions[current]} 
      />
        
      <Answers
        answers={questions[current].answers} 
        answersImages={questions[current].answersImages}
        correctAnswer={questions[current].correctAnswer}
        colors={questions[current].colors}
      />
        
      <GameControl
        nextQuestion={nextQuestionOrResult}
        previousQuestion={previousQuestion} 
        stopQuiz={stopQuiz}
      />
    </>)}
    </>
  )
}

export default QuizScreen
